<!--
 * @Author: 候怀烨
 * @Date: 2021-01-19 15:27:58
 * @LastEditTime: 2021-01-19 15:55:21
 * @LastEditors: Please set LastEditors
 * @Description: 网销活动
 * @FilePath: \sd-vue-admin\src\views\project\marketing\activity\index.vue
-->
<template>
  <router-view></router-view>
</template>

<script>
  export default {}
</script>

<style></style>
